<template>
  <h1>Доставка</h1>
  <div class="delivery-container">
    <div class="image-section">
      <img :src="require('@/assets/delivery.png')" />
    </div>

    <div class="text-section">
      <h4>КаМаз длинномер:</h4>
      <p>- правый берег 40 000т.<br>
      - левый берег 50 000тг.<br>
      <h4>Газель:</h4>
      -правый берег 8 000тг.<br>
      -левый берег 10 000тг.<br><br>
      <b>Свыше 10 тн бесплатно</b></p>
      <p>Остались вопросы? Мы на них ответим!</p>
      <div class="card flex justify-center">
        <Sidebar v-model:visible="visible" header="Мы свяжемся с вами!">
          <Feedback />
        </Sidebar>
        <Button label="Обратная связь" @click="visible = true" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Feedback from "@/components/Feedback";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";


export default {
  name: "DeliveryPage",
  components: {
    Feedback,
    Sidebar,
    Button
  },
  setup() {
    const visible = ref(false);
    return { visible };
  },
};
</script>

<style>
.delivery-container {
  display: flex;
  flex-wrap: wrap;
}

.image-section,
.text-section {
  min-width: 400px;
  flex: 1;
}

/* .text-section {
  background-color: #282936;

} */

.text-section,
.image-section img {
  max-width: 90%;
  height: auto;
  justify-content: center;
  border-radius: 15px;
}
</style>
