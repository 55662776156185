<template>
  <div class="home">
    <div class="head">
      <h1>Стальная марка</h1>
      <h2>Прочность в каждой нити, надежность в каждой сварке</h2>
    </div>

    <Sidebar v-model:visible="visible" header="Мы свяжемся с вами!">
      <Feedback />
    </Sidebar>
    <Button severity="success" outlined label="Обратная связь" @click="visible = true" />

    <section class="products">
      <h2>Продукция</h2>
      <div class="product-grid">
        <Card v-for="(product, index) in products" :key="index" class="product">
          <template #header>
            <img :src="product.image" :alt="product.title" />
          </template>
          <template #title>{{ product.title }}</template>
          <template #content>
              <p class="m-0">{{ product.short_description }}</p>
          </template>
          <template #footer>
            <router-link
              :to="{ name: 'Product', params: { id: product.id } }"
              style="text-decoration: none"
            >
              <Button label="Заказать" />
            </router-link>
          </template>
        </Card>
      </div>
    </section>

    <section class="partners pt-[10px]">
      <h2>Наши Партнеры</h2>
      <Carousel
        :value="partners"
        :numVisible="4"
        :numScroll="1"
        class="partners-carousel"
        :responsiveOptions="responsiveOptions"
        circular
        :autoplayInterval="3000"
      >
        <template #item="slotProps">
          <div class="mb-3">
            <img :src="slotProps.data" class="partner-logo" />
          </div>
        </template>
      </Carousel>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted, mounted } from "vue";
import Card from "primevue/card";
import DataView from "primevue/dataview";
import Carousel from "primevue/carousel";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Fieldset from "primevue/fieldset";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Feedback from "@/components/Feedback.vue";
import Sidebar from "primevue/sidebar";
import { getProductsMain } from "@/service/ProductService";

export default {
  components: {
    Card,
    Carousel,
    Button,
    Fieldset,
    DataView,
    DataViewLayoutOptions,
    Feedback,
    Sidebar,
    Tag,
  },
  data() {
    return {
      products: null,
      layout: "grid",
      visible: false,
    };
  },

  setup() {
    const products = ref([]);

    onMounted(async () => {
      try {
        const response = await getProductsMain();
        // console.log('response: ', response)
        products.value = response.data;
      } catch (error) {
        console.error("Ошибка при загрузке продуктов:", error);
      }
    });

    const partners = computed(() =>
      Array.from({ length: 9 }, (_, i) => require("@/assets/partner" + i + ".png"))
    );

    const responsiveOptions = ref([
      {
        breakpoint: "1199px",
        numVisible: 3,
        numScroll: 2,
      },
      {
        breakpoint: "991px",
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: "767px",
        numVisible: 1,
        numScroll: 1,
      },
    ]);

    return { partners, responsiveOptions, products };
  },

  methods: {
    getSeverity(product) {
      switch (product.inventoryStatus) {
        case "INSTOCK":
          return "success";

        case "LOWSTOCK":
          return "warning";

        case "OUTOFSTOCK":
          return "danger";

        default:
          return null;
      }
    },
  },
};
</script>

<style>
.partner-logo {
  width: 250px;
}

.products {
  padding: 1%;
}

.head h1,
h2 {
  margin: 0.5% 3%;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.product img {
  width: 70%;
  height: auto;
  margin: 25px 15px;
  border-radius: 10px;
}

.p-card {
  width: 25em;
  margin: 1%;
}

.data-view-img {
  width: 20em;
  margin: 1%;
}

/* .p-card-content {
  display: none;
} */

@media (max-width: 600px) {
  .p-card {
    width: 10em;
    margin: 0.5%;
  }

  .product-grid {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap;
  gap: 10px;
  justify-content: center; */
  }

  .p-card-title {
    font-size: 16px;
  }

  .p-card-subtitle {
    font-size: 12px;
  }

  .p-card-content {
    font-size: 10px;
  }
}
</style>
