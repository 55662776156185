<template>
  <div v-if="selectedProduct">
    <div v-for="(options, param) in selectedProduct.parameters" :key="param">
      <h5>{{ param }}</h5>
      <SelectButton :options="options" v-model="selectedOptions[param]" />
    </div>

    <div v-if="selectedPriceCombination">
      <div v-if="selectedPriceCombination.weight">
        Вес: {{ selectedPriceCombination.weight }}
      </div>
      <div>Цена: {{ selectedPriceCombination.price }} тенге {{ selectedProduct.price_in }}</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import SelectButton from "primevue/selectbutton";

export default {
  components: { SelectButton },
  setup() {
    const products = ref([]);
    const selectedProduct = ref(null);
    const selectedOptions = ref({});

    onMounted(async () => {
      try {
        const response = await fetch("/products.json");
        if (response.ok) {
          products.value = await response.json();
          selectedProduct.value = products.value[0];
          for (const key in selectedProduct.value.parameters) {
            selectedOptions.value[key] = selectedProduct.value.parameters[key][0];
          }
        } else {
          throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
        }
      } catch (error) {
        console.error("Ошибка при загрузке продуктов:", error);
      }
    });

    const selectedPriceCombination = computed(() => {
      return selectedProduct.value.prices.find((price) => {
        return Object.keys(price.combination).every((key) => {
          return price.combination[key] == selectedOptions.value[key];
        });
      });
    });


    // console.log(selectedProduct) 
    return { selectedProduct, selectedOptions, selectedPriceCombination };
  },
};
</script>