import { createStore } from 'vuex'

export default createStore({
  state: {
    totalParameters: 0
  },
  getters: {
  },
  mutations: {
    updateTotalParameters(state, value) {
      state.totalParameters = value;
    }
  },
  actions: {
  },
  modules: {
  }
})


