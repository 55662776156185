<template>
  <div>
    <InputText class="inp" v-model="newFeedback.name" placeholder="Имя" />
    <InputMask
      class="inp"
      v-model="newFeedback.phone"
      mask="+9 (999) 999-9999"
      placeholder="+9 (999) 999-9999"
    />
    <InputText 
      class="inp" 
      v-model="newFeedback.email" 
      placeholder="Email" />
    <InputText
      class="inp"
      v-model="newFeedback.comment"
      placeholder="Комментарий (по желанию)"
    /><br />

    <Checkbox class="inp" v-model="consent" binary />
    <div class="agree">
    <span @click="showConsentDialog" 
      >Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных
      данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О
      персональных данных», на условиях и для целей, определенных в Согласии на обработку
      персональных данных</span
    >
      </div>


    <Dialog
      v-model:visible="displayConsent"
      modal
      header="Согласие на обработку персональных данных"
      :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <p>
        Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от
        27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное
        согласие на обработку моих персональных данных , зарегистрированным в соответствии
        с законодательством РФ по адресу: (далее по тексту - Оператор).
      </p>
      <p>
        1. Согласие дается на обработку одной, нескольких или всех категорий персональных
        данных, не являющихся специальными или биометрическими, предоставляемых мною,
        которые могут включать:
      </p>
      - Имя;<br/>
      - Телефон;<br/>
      - E-mail;<br/>
      - Комментарий.<br/>
      <p>
        2. Оператор может совершать следующие действия: сбор; запись; систематизация;
        накопление; хранение; уточнение (обновление, изменение); извлечение;
        использование; блокирование; удаление; уничтожение.
      </p>
      <p>
        3. Способы обработки: как с использованием средств автоматизации, так и без их
        использования.
      </p>
      <p>
        4. Цель обработки: предоставление мне услуг/работ, включая, направление в мой
        адрес уведомлений, касающихся предоставляемых услуг/работ, подготовка и
        направление ответов на мои запросы, направление в мой адрес информации о
        мероприятиях/товарах/услугах/работах Оператора.
      </p>
      <p>
        5. В связи с тем, что Оператор может осуществлять обработку моих персональных
        данных посредством программы для ЭВМ «1С-Битрикс24», я даю свое согласие Оператору
        на осуществление соответствующего поручения ООО «1С-Битрикс», (ОГРН
        5077746476209), зарегистрированному по адресу: 109544, г. Москва, б-р Энтузиастов,
        д. 2, эт.13, пом. 8-19.
      </p>
      <p>
        6. Настоящее согласие действует до момента его отзыва путем направления
        соответствующего уведомления на электронный адрес ms7555277@yandex.kz.
      </p>
      <p>
        7. В случае отзыва мною согласия на обработку персональных данных Оператор вправе
        продолжить обработку персональных данных без моего согласия при наличии оснований,
        предусмотренных Федеральным законом №152-ФЗ «О персональных данных» от 27.07.2006
        г.
      </p>
      <template #footer>
        <Button label="Ок" severity="success" @click="displayConsent = false" autofocus />
      </template>
    </Dialog>
    <Button class="inp" @click="submitFeedback" :disabled="!canSubmit">Отправить</Button>



    <Dialog
      v-model:visible="displaySentFeedback" modal :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <p name="create-feedback" class="m-0">
        Ваше обращение отправлено! Мы свяжемся с вами в ближайшее время!
      </p>

      <template #footer>
        <Button label="Ок" severity="success" @click="displaySentFeedback = false" autofocus />
      </template>
    </Dialog>

  </div>
</template>

<script>
import { ref, computed } from "vue";
import { createFeedback } from "@/service/ProductService";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default {
  components: {
    InputText,
    InputMask,
    Checkbox,
    Dialog,
    Button,
  },
  setup() {
    const newFeedback = ref({
      name: "",
      phone: "",
      email: "",
      comment: "",
    });
    // const name = ref("");
    // const phone = ref("");
    // const email = ref("");
    // const comment = ref("");
    const consent = ref(true);
    const displayConsent = ref(false);
    const createdFeedback = ref(null);
    const displaySentFeedback = ref(false)

    const canSubmit = computed(() => {
      return (
        newFeedback.value.name.trim() !== "" &&
        (newFeedback.value.phone.trim() !== "" || newFeedback.value.email.trim() !== "") &&
        consent.value
      );
    });

    function showConsentDialog() {
      displayConsent.value = true;
    }

    // function showConsentDialog() {
    //   displayConsent.value = true;
    // }




    const submitFeedback = async () => {
      if (!canSubmit.value) return;

      try {
        createdFeedback.value = await createFeedback(newFeedback.value);
        // console.log(createdFeedback);
        displaySentFeedback.value = true;

      } catch (error) {
        console.error("Error in createFeedback", error);
      };
      newFeedback.value = {
        name: "",
        phone: "",
        email: "",
        comment: "",
      };
    }

    return {
      // name,
      // phone,
      // email,
      // comment,
      newFeedback,
      consent,
      displayConsent,
      displaySentFeedback,
      canSubmit,
      showConsentDialog,
      submitFeedback,
    };
  },
};
</script>

<style>
.inp {
  margin: 5px 5px;
  /* width: 100%;
  min-width: 340px; */
  width: 250;

  display: flex;
  flex-direction: row;
}

.agree {
  font-size: 12px;
  margin: 5px 10px;
  /* min-width: 250px; */
  width: 90%;
  text-align: justify;
  cursor: pointer;
}

.p-inputtext {
  min-width: 250px;
  /* min-width: 340px; */
  display: flex;
  flex-direction:  row;
}
</style>
