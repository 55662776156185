<template>
  <div class="navbar">
    <Menubar :model="menuItems">
      <template #start>
        <router-link to="/">
          <img
            alt="logo"
            src="@/assets/stalnayamarka.png"
            style="border-radius: 0"
            class="logo-img"
          />
        </router-link>
      </template>
      <template #item="{ label, item, props, root, hasSubmenu }">
        <router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
          <a :href="routerProps.href" v-bind="props.action">
            <span v-bind="props.icon" />
            <span v-ripple v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span
            v-ripple
            :class="[
              hasSubmenu && (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right'),
            ]"
            v-bind="props.submenuicon"
          />
        </a>
      </template>

      <template #end>
        <div class="temp_end">
          <div>
            <router-link to="/cart">
              <Button
                v-if="totalParameters !== 0"
                icon="pi pi-shopping-cart"
                :badge="totalParameters.toString()"
                class="cart"
              />
            </router-link>
          </div>

          <div>
            <Button
              severity="success"
              label="Обратная связь"
              @click="visible = true"
              class="feedback"
            />
          </div>
          <div class="contact-info">
            <a href="tel:+77057555277">
              <i class="pi pi-phone icon"></i>
              <span class="text">+7 705 755 5277</span>
            </a>

            <a href="mailto:ms7555277@yandex.kz">
              <i class="pi pi-envelope icon"></i>
              <span class="text">ms7555277@yandex.kz</span>
            </a>

            <a
              href="https://wa.me/+77057555277?text=Здравствуйте,%20интересует%20продукция%20продукция%20стальной%20марки."
            >
              <i class="pi pi-whatsapp green-icon icon"></i>
              <span class="text">WhatsApp</span>
            </a>
          </div>

          <SpeedDial
            :model="topItems"
            direction="down"
            showIcon="pi pi-phone"
            class="mobile-only"
            buttonClass="p-button-raised"
            :style="{ left: 'calc(100% - 70px)', top: '40px' }"
          />
        </div>
      </template>
    </Menubar>
    <Sidebar v-model:visible="visible" header="Мы свяжемся с вами!">
      <Feedback />
    </Sidebar>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed } from "vue";
import Menubar from "primevue/menubar";
import Feedback from "@/components/Feedback.vue";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import SpeedDial from "primevue/speeddial";
// import InputSwitch from 'primevue/inputswitch';
import { useStore } from "vuex";
import { getProductsMain } from "@/service/ProductService";
import { usePrimeVue } from "primevue/config";

// const PrimeVue = usePrimeVue();
// PrimeVue.changeTheme('soho-dark', 'soho-light', 'theme-link', () => {});

const store = useStore();

const visible = ref(false);
// localStorage.clear("cart");
let cart = JSON.parse(localStorage.getItem("cart"));
// console.log('cart--> Navbar: ', cart)
const totalCart = ref(0);

if (cart) {
  totalCart.value = 0;
  cart.forEach((product) => {
    if (product.parameters) {
      // console.log("product.parameters.length: ", product.parameters.length);
      totalCart.value += product.parameters.length;
    }
  });
}
store.commit("updateTotalParameters", totalCart.value);

const topItems = ref([
  {
    label: "+7 705 755 5277",
    icon: "pi pi-phone",
    // url: "tel:+77057555277"
    command: () => {
      window.location.href = "tel:+77057555277";
    },
  },
  {
    label: "ms7555277@yandex.kz",
    icon: "pi pi-envelope",
    command: () => {
      window.location.href = "mailto:ms7555277@yandex.kz";
    },
  },
  {
    label: "+7 705 755 5277",
    icon: "pi pi-whatsapp green-700",
    //   url:
    //     "https://wa.me/+77057555277?text=Здравствуйте,%20интересует%20продукция%20продукция%20стальной%20марки.",
    command: () => {
      window.location.href = "https://wa.me/+77057555277?text=Здравствуйте,%20интересует%20продукция%20продукция%20стальной%20марки."
    },
  },
]);

const menuItems = ref([
  {
    label: "Главная",
    route: "/",
  },
  {
    label: "Продукция",
    items: [
      { label: "Сетка сварная в рулонах", route: "/product/30356" },
      { label: "Сетка сварная в листах", route: "/product/30358" },
      { label: "Профист НС21 оцинкованный", route: "/product/30357" },
      { label: "Профист НС35 оцинкованный", route: "/product/30370" },
    ],
  },
  {
    label: "Контакты",
    route: "/contacts",
  },
  {
    label: "Доставка",
    route: "/delivery",
  },
  {
    label: "О нас",
    route: "/about",
  },
  {
    label: "Связаться c нами",
    icon: "pi pi-phone",
    items: [
      {
        label: "+7 705 755 5277",
        icon: "pi pi-phone",
        url: "tel:+77057555277",
      },
      {
        label: "ms7555277@yandex.kz",
        icon: "pi pi-envelope",
        url: "mailto:ms7555277@yandex.kz",
      },
      {
        label: "+7 705 755 5277",
        icon: "pi pi-whatsapp",
        url:
          "https://wa.me/+77057555277?text=Здравствуйте,%20интересует%20продукция%20продукция%20стальной%20марки.",
      },
    ],
    class: "mobile-only",
  },
]);

const totalParameters = computed(() => store.state.totalParameters);

// console.log("Общее количество параметров: " + totalParameters);
</script>

<style>
.contact-info-mobile {
  display: none;
}

.p-submenu-list {
  min-width: 223px;
}

.green-icon {
  color: #a3e7c1;
}

.green-700 {
  color: #176d3d;
}

.p-speeddial-button,
.p-button-rounded,
.p-speeddial-rotate {
  width: 50px;
  height: 50px;
}

.cart {
  padding: 7px;
  margin-top: 45px;
  margin-right: 10px;
}

.feedback {
  padding: 7px;
  margin-top: 45px;
  margin-right: 25px;
  min-width: 150px;
  flex: none;
  /* color: --green-600;
  background-color: #FFFFF; */
}

.temp_end {
  display: flex;
  flex-direction: row;
}

.contact-info a {
  display: flex;
  align-items: left;
  color: white;
  text-decoration: none;
  margin-bottom: 5px;
  margin-right: 25px;
}

.contact-info a:hover {
  color: rgb(163, 183, 255);
}

.contact-info .text {
  margin-left: 5px;
}

.icon {
  height: 20px;
  margin-top: 4px;
  border-radius: 0;
}

.p-menubar-button,
.p-menuitem-link {
  text-align: left;
}
/* .p-menuitem-link */

.logo-img {
  height: 60px;
}

.mobile-only {
  display: none;
}
/* 
.p-menubar-mobile {
  height: 130px;
} */
@media (max-width: 960px) {
  .center-section {
    position: static;
    transform: none;
    margin-left: auto;
  }

  .contact-info {
    display: none;
  }

  .contact-info-mobile {
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
  }

  .contact-info-mobile .pi {
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    margin: 0 10px;
    padding: 7px 7px;
  }

  .p-menubar-button {
    size: 2rem;
  }

  .mobile-only {
    display: block;
  }

  .logo-img {
    height: 40px;
  }

  .cart {
    padding: 5px;
    margin-top: 0;
    /* margin-top: 80px; 
  margin-right: 10px; */
    font-size: 10px;
  }

  .feedback {
    padding: 5px;
    margin-top: 0;
    /* margin-top: 80px; */
    margin-right: 0;
    font-size: 12px;
    min-width: 100px;
    /* padding: 5px; 
  margin-top: 80px; 
  margin-right: 0;
  
  font-size: 12px; */
  }

  .p-menubar {
    padding-bottom: 50px;
  }
  .p-speeddial-button,
  .p-speeddial-action {
    /* margin-left: 50px; */
    margin-top: 15px;
    height: 40px;
    width: 40px;
  }
}

/* .p-menuitem-text, .p-menuitem-icon, .p-submenu-icon {
    color: #505467;
}

.p-menubar, 
.p-menuitem-link, 
.p-submenu-icon :hover {
    color: #e31212;
} */
</style>
