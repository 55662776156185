// import axios from 'axios';

// const http = axios.create({
//     baseURL: 'http://127.0.0.1:8000/',
// });

// export default http;

import axios from 'axios';

const http = axios.create({
  baseURL: 'https://anastas1a.pythonanywhere.com',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
});

export default http;