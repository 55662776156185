
import http from "@/axios.js";




export const createFeedback = async (newFeedback) => {
    console.log('newFeedback:  ', newFeedback)
    try {
        const response = await http.post('api/create-feedback/', newFeedback);
        return response.data;
    } catch (error) {
        console.error('Error in ProductService -> createFeedback', error);
        throw error;
    }
};


export const createOrder = async (newOrder) => {
    console.log('newOrder:  ', newOrder)
    try {
        const response = await http.post('api/create-order/', newOrder);
        return response.data;
    } catch (error) {
        console.error('Error in ProductService -> createOrder', error);
        throw error;
    }
};




export const getProductsMain = async () => {
    try {
        const response = await http.get('api/products/');
        return response.data;
    } catch (error) {
        console.error('Error in ProductService -> getProducts', error);
        throw error;
    }
};

export const getProductId = async (id) => {
    try {
        const response = await http.get(`api/products/${id}/`);
        return response.data;
    } catch (error) {
        console.error('Error in ProductService -> getProductId', error);
        throw error;
    }
};

export const loginToken = async (cartData) => {
    try {
        const response = await http.post("api/process_cart_items/", cartData);

        if (response.data.access) {
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
        } else {
            alert("Invalid credentials");
        }
    } catch (error) {
        console.error('Error in ProductService -> loginToken', error);

        if (error.response && error.response.status === 401) {
            alert("Invalid credentials");
        }
    }
}



export const getCart = async (token) => {
    console.log('token:  ', token)
    try {
        const response = await http.get(`api/cart-by-token/${token}/`, newFeedback);
        return response.data;
    } catch (error) {
        console.error('Error in ProductService -> getCart', error);
        throw error;
    }
};

