<template>
    <div id="map-wrap">
        <div id="map-block">
            <div id="map"></div>
        </div>
        <div id="map-settings"></div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      coordinates: [43.300608, 76.907969],
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const { ymaps } = window;

      ymaps.ready(() => {
        const map = new ymaps.Map("map", {
          center: this.coordinates,
          zoom: 15,
        });

        const placemark = new ymaps.Placemark(
            this.coordinates,
            {},
            {
                preset: "islands#icon",
                iconColor: "#0095b6",
            }
        );

        map.geoObjects.add(placemark);
      });
    },
  },
};
</script>

<style>
#map-wrap {
  display: flex;
  flex: 1 1 0;
}

#map-block {
  flex: 1 1 0;
  position: relative;
  width: 300px;
  z-index: 10;
}

#map-settings {
  height: 300px;
}

#map {
  width: 100%;
  height: 100%;
}
</style>
