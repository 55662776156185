<template>
  <div class="product_info" v-if="selectedProduct">
    <div class="product-title">
      <h2>{{ selectedProduct.title }}</h2>
      <h4>{{ selectedProduct.short_description }}</h4>
      <!-- <p> {{ selectedProduct.description }} </p> -->
      <div v-for="line in selectedProduct.description" :key="line">
        <p>{{ line }}</p>
      </div>
    </div>
    <img
      class="product-header"
      :src="selectedProduct.image"
      :alt="selectedProduct.title"
    />
  </div>

  <div class="table">
    <div v-if="selectedProduct">
      <DataTable size="small" class="data-table" :value="selectedProduct.parameters">
        <div v-for="(value, key) in selectedProduct.parameters[0].value" :key="key">
          <Column
            :field="'value.' + key + '.value'"
            :header="value.parameter.name"
          ></Column>
        </div>
        <Column
          field="price"
          :header="'Цена за ' + selectedProduct.price_in + ', тенге'"
        ></Column>
        <Column header="Количество">
          <template #body="slotProps">
            <InputNumber class="table-inp" v-model="slotProps.data.quantity" :min="0" />
          </template>
        </Column>
        <Column header="Итого">
          <template #body="slotProps">
            {{
              (slotProps.data.quantity * slotProps.data.price || 0).toLocaleString(
                "ru-RU"
              )
            }}
          </template>
        </Column>
      </DataTable>
      <div class="total-sum text-right text-lg font-bold">
        Итого: {{ totalSum.toLocaleString("ru-RU") }} тенге
        <Button
          icon="pi pi-shopping-cart"
          label="Заказать"
          :disabled="totalSum === 0"
          class="flex-auto md:flex-initial white-space-nowrap buy"
          @click="addToCart"
        ></Button>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="visible" modal header="Товары добавлены в корзину">
    <DataTable size="small" class="data-table" :value="cartData.parameters">
      <div v-for="(value, key) in cartData.parameters[0].value" :key="key">
        <Column
          :field="'value.' + key + '.value'"
          :header="value.parameter.name"
        ></Column>
      </div>
      <!-- <Column field="price" :header="'Цена за ' + price_in + ', тенге'"></Column> -->
      <Column header="Количество">
        <template #body="slotProps">
          {{ slotProps.data.quantity }}
        </template>
      </Column>
      <Column header="Итого">
        <template #body="slotProps">
          <!-- {{ slotProps.data.price }} -->
          {{
            (slotProps.data.quantity * slotProps.data.price || 0).toLocaleString("ru-RU")
          }}
        </template>
      </Column>
    </DataTable>
    <div class="flex pt-4 justify-between">
      <Button
        size="small"
        link
        label="Продолжить покупки"
        @click="visible = false"
      />
    <router-link to="/cart">
      <Button
        size="small"
        icon="pi pi-shopping-cart"
        label="Оформить"
      />
    </router-link>
      </div>
  </Dialog>
</template>

<script setup>
import { ref, reactive, onMounted, watch, toRefs, computed, unref, toRaw } from "vue";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import { useRoute } from "vue-router";
import { getProductId, loginToken } from "@/service/ProductService";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();
const productId = ref(route.params.id);
const visible = ref(false);

const products = ref([]);
const selectedProduct = ref(null);
const filteredPrices = ref([]);
const cartData = ref([]);
let cart = [];
const totalParameters = ref(0);

const totalSum = computed(() => {
  return selectedProduct.value.parameters.reduce(
    (sum, param) => sum + (param.quantity || 0) * param.price,
    0
  );
});

function resetQuantities(product) {
  // console.log(product);
  if (product) {
    product.parameters.forEach((param) => {
      param.quantity = null;
    });
  }
}

const addToCart = async () => {
  // localStorage.clear("cart");

  cartData.value = {
    productId: selectedProduct.value.id,
    price_in: selectedProduct.value.price_in,
    title: selectedProduct.value.title,
    parameters: selectedProduct.value.parameters
      .filter(
        (param) =>
          typeof param.quantity !== "undefined" &&
          param.quantity !== null &&
          param.quantity !== 0
      )
      .map((param) => ({
        value: param.value,
        price: param.price,
        quantity: param.quantity,
      })),
  };

  // if (!cartData.parameters) {
  //   console.log("Пустой заказ");
  //   return;
  // }

  if (localStorage.getItem("cart")) {
    cart = JSON.parse(localStorage.getItem("cart"));
  }
  // console.log("cart1", cart);

  let unrefCartData = unref(cartData);
  const existingProductIndex = cart.findIndex(
    (item) => item.productId === unrefCartData.productId
  );

  if (existingProductIndex !== -1) {
    unrefCartData.parameters.forEach((dataParam) => {
      const existingParam = cart[existingProductIndex].parameters.find(
        (param) => JSON.stringify(param.value) == JSON.stringify(dataParam.value)
      );
      // console.log("existingParam: ", existingParam);
      if (existingParam) {
        // console.log(
        //   "existingParam.quantity: ",
        //   existingParam.quantity,
        //   "dataParam.quantity: ",
        //   dataParam.quantity
        // );
        existingParam.quantity += dataParam.quantity;
      } else {
        cart[existingProductIndex].parameters.push(dataParam);
      }
    });
  } else {
    cart.push(unrefCartData);
  }
  // console.log("cart2", cart);

  // console.log("unrefCartData:  ", unrefCartData);

  localStorage.setItem("cart", JSON.stringify(cart));

  const cartTest = JSON.parse(localStorage.getItem("cart"));
  visible.value = true;

  if (cartTest) {
    totalParameters.value = 0;
    cartTest.forEach((product) => {
      if (product.parameters) {
        // console.log("product.parameters.length: ", product.parameters.length);
        totalParameters.value += product.parameters.length;
      }
    });
  }
  // console.log("totalParameters: ", totalParameters);
  store.commit("updateTotalParameters", totalParameters.value);
  // console.log("cartTest:  ", cartTest);
  resetQuantities(selectedProduct.value);
};


// const confirmDeleteProduct = (prod) => {
//     product.value = prod;
//     deleteProductDialog.value = true;
// };



onMounted(async () => {
  try {
    const response = await getProductId(productId.value);

    selectedProduct.value = response;
    // console.log("selectedProduct: ", selectedProduct);
  } catch (error) {
    console.error("Ошибка при загрузке продуктa:", error);
  }
});

watch(productId, (newId) => {
  selectedProduct.value = products.value.find((p) => p.id == newId);
  if (selectedProduct.value) {
    filteredPrices.value = selectedProduct.value.prices.map((price) => {
      return { ...price, quantity: 0 };
    });
  }
});
</script>

<style>
.product-header {
  min-height: 100px;
  min-width: 100px;
  max-height: 400px;
  max-width: 400px;
  border-radius: 25px;
  /* height: auto; */
  margin: 10px;
}

.product_info {
  /* width: 25em; */
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse; */
  margin: 0 5%;
  justify-content: center;
}

/* .product-title {
  max-width: 400px;
  justify-content: center;
} */

.responsive-input-number,
.p-inputnumber,
.p-inputnumber-input {
  max-width: 200px;
  min-width: 50px;
}

/* .table-inp {
  max-width: 200px;
  min-width: 50px;
} */

.total-sum {
  margin: 2% 0;
}

.buy {
  margin-left: 2%;
}

.table {
  display: flex;
  margin: 2% 0;
  justify-content: center;
}

.mobile-view {
  display: none;
}

@media (max-width: 768px) {
  /* .responsive-input-number,
  .p-inputnumber,
  .p-inputtext,
  .p-inputnumber-input {
    
  } */

  .responsive-input-number,
  .p-inputnumber,
  .p-inputnumber-input {
    font-size: 11px;
  }

  .table-inp {
    width: 70px;
    font-size: 10px;
  }

  .product-header {
    height: 270px;
    width: 270px;
    margin: 10px;
  }

  .data-table {
    font-size: 11px;
  }

  .table {
    margin: 0 1rem;
  }
}
/* .p-inputtext,
.p-component,
.p-inputnumber-input {
  min-width: 50px;
} */
</style>
