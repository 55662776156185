<template>
  <div>
    <h2>Свидетельство НДС</h2>
    <div class="doc-container">
      <div class="doc">
        <Image :src="require('@/assets/nds_ru.png')" alt="NDS KZ" width="350" preview />
      </div>

      <div class="doc">
        <Image :src="require('@/assets/nds_kz.png')" alt="NDS RU" width="350" preview />
      </div>
    </div>
  </div>
</template>

<script>
import Image from "primevue/image";

export default {
  components: {
    Image,
  },
};
</script>

<style>
.doc-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.doc {
    padding: 20px;
}



/* .p-image-preview-indicator {
    width: 250px;
  }

@media (max-width: 768px) {
  .p-image-preview-indicator {
    width: 200px;
  }
} */
</style>
