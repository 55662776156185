import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import './tailwind.css';
// import components from  '@/components/UI'
// import 'primevue/resources/themes/soho-light/theme.css'; 
import 'primevue/resources/themes/soho-dark/theme.css'; 
import 'primevue/resources/primevue.min.css'; 
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import 'animate.css/animate.css';
// import './tailwind.css'


const app = createApp(App)
app.directive('ripple', Ripple);
// components.forEach(component => {
//     app.component(component.name, component)
// })
app.use(PrimeVue, { ripple: true }).use(router).use(store).mount('#app')
// app.use(store).use(router).mount('#app')

// const themeLink = document.getElementById('theme-link');

// if (themeLink) {
//   themeLink.setAttribute('href', 'primevue/resources/themes/soho-dark/theme.css');
// }