import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/pages/Main.vue'
import ContactsPage from '@/pages/ContactsPage.vue'
import CartPage from '@/pages/CartPage.vue'
import DeliveryPage from '@/pages/DeliveryPage.vue'
import AboutPage from '@/pages/AboutPage.vue'
import Price from '@/components/Price.vue'
import Product from '@/components/Product.vue'

const routes = [
  {
    path: '/',
    component: Main
  },
  {
    path: '/contacts',
    component: ContactsPage
  },
  {
    path: '/delivery',
    component: DeliveryPage
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product,
    props: true
  },
  {
    path: '/about',
    component: AboutPage
  },
  {
    path: '/cart',
    component: CartPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
