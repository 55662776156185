<template>
  <div class="card flex justify-center min-w-50">
    <Stepper linear class="min-w-20" >
      <StepperPanel class="min-w-2" header="Корзина">
        <template #content="{ nextCallback }">
          <div v-for="selectedProduct in cart">
            <h3>{{ selectedProduct.title }}</h3>
            <div class="table flex-auto flex">
              <div v-if="selectedProduct">
                <DataTable
                  size="small"
                  class="data-table"
                  :value="selectedProduct.parameters"
                >
                  <div
                    v-for="(value, key) in selectedProduct.parameters[0].value"
                    :key="key"
                  >
                    <Column
                      :field="'value.' + key + '.value'"
                      :header="value.parameter.name"
                    ></Column>
                  </div>
                  <Column
                    field="price"
                    :header="'Цена за ' + selectedProduct.price_in + ', тенге'"
                  ></Column>
                  <Column header="Количество">
                    <template #body="slotProps">
                      <InputNumber
                        class="table-inp"
                        v-model="slotProps.data.quantity"
                        :min="0"
                      >
                        <template #incrementbuttonicon>
                          <span class="pi pi-plus" />
                        </template>
                        <template #decrementbuttonicon>
                          <span class="pi pi-minus" />
                        </template>
                      </InputNumber>
                    </template>
                  </Column>
                  <Column header="Итого">
                    <template #body="slotProps">
                      {{
                        (
                          slotProps.data.quantity * slotProps.data.price || 0
                        ).toLocaleString("ru-RU")
                      }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
          <div v-if="cart.length > 0" class="total-sum text-right text-lg font-bold">
            Итого: {{ totalSum.toLocaleString("ru-RU") }} тенге
          </div>
          <div v-else>
            <h2>В корзине ничего нет</h2>
          </div>
          <div v-if="cart.length > 0" class="flex pt-4 justify-end">
            <Button
              label="Дальше"
              icon="pi pi-arrow-right"
              iconPos="right"
              @click="nextCallback(), changeCart()"
            />
          </div>
        </template>
      </StepperPanel>
      <StepperPanel header="Доставка">
        <template #content="{ prevCallback, nextCallback }">
          <div>
          <div class="pb-5">
            <Checkbox v-model="delivery" :binary="true" inputId="delivery"/>
            <label for="delivery" class="ml-2"> Доставка</label>
          </div>
          <InputText class="inp justify-center" v-model="order.delivery" v-if="delivery" placeholder="Адрес (по желанию)" />
          <div class="border border-solid surface-border rounded surface-ground flex-auto justify-left items-center font-medium">
             <h4>КаМаз длинномер:</h4>
            <p>- правый берег 40 000 тг.<br>
            - левый берег 50 000 тг.<br>
            <h4>Газель:</h4>
            -правый берег 8 000 тг.<br>
            -левый берег 10 000 тг.<br><br>
            <b>Свыше 10 тн бесплатно</b></p>
            </div>

          </div>
          <div class="flex pt-4 justify-between">
            <Button
              label="Назад"
              severity="secondary"
              icon="pi pi-arrow-left"
              @click="prevCallback"
            />
            <Button
              label="Дальше"
              icon="pi pi-arrow-right"
              iconPos="right"
              @click="nextCallback"
            />
          </div>
        </template>
      </StepperPanel>
      <StepperPanel header="Оформление">
        <template #content="{ prevCallback }">
          <div class="flex flex-column h-12rem">
            <div class="surface-ground justify-center items-center font-medium">

              <InputText class="inp" v-model="order.name" placeholder="Имя" />
              <InputMask
                class="inp"
                v-model="order.phone"
                mask="+9 (999) 999-9999"
                placeholder="+9 (999) 999-9999"
              />
              <InputText 
                class="inp" 
                v-model="order.email" 
                placeholder="Email" />
              <InputText
                class="inp"
                v-model="order.comment"
                placeholder="Комментарий (по желанию)"
              /><br />

              <Checkbox class="inp" v-model="consent" binary />
              <div class="agree max-w-80">
              <span @click="showConsentDialog" 
                >Нажимая кнопку «Подтвердить», я даю свое согласие на обработку моих персональных
                данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О
                персональных данных»...</span
              >
            </div>


            <Dialog
              v-model:visible="displayConsent"
              modal
              header="Согласие на обработку персональных данных"
              :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
            >
              <p>
                Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от
                27.07.2006 года свободно, своей волей и в своем интересе выражаю свое безусловное
                согласие на обработку моих персональных данных , зарегистрированным в соответствии
                с законодательством РФ по адресу: (далее по тексту - Оператор).
              </p>
              <p>
                1. Согласие дается на обработку одной, нескольких или всех категорий персональных
                данных, не являющихся специальными или биометрическими, предоставляемых мною,
                которые могут включать:
              </p>
              - Имя;<br/>
              - Телефон;<br/>
              - E-mail;<br/>
              - Комментарий.<br/>
              <p>
                2. Оператор может совершать следующие действия: сбор; запись; систематизация;
                накопление; хранение; уточнение (обновление, изменение); извлечение;
                использование; блокирование; удаление; уничтожение.
              </p>
              <p>
                3. Способы обработки: как с использованием средств автоматизации, так и без их
                использования.
              </p>
              <p>
                4. Цель обработки: предоставление мне услуг/работ, включая, направление в мой
                адрес уведомлений, касающихся предоставляемых услуг/работ, подготовка и
                направление ответов на мои запросы, направление в мой адрес информации о
                мероприятиях/товарах/услугах/работах Оператора.
              </p>
              <p>
                5. В связи с тем, что Оператор может осуществлять обработку моих персональных
                данных посредством программы для ЭВМ «1С-Битрикс24», я даю свое согласие Оператору
                на осуществление соответствующего поручения ООО «1С-Битрикс», (ОГРН
                5077746476209), зарегистрированному по адресу: 109544, г. Москва, б-р Энтузиастов,
                д. 2, эт.13, пом. 8-19.
              </p>
              <p>
                6. Настоящее согласие действует до момента его отзыва путем направления
                соответствующего уведомления на электронный адрес ms7555277@yandex.kz.
              </p>
              <p>
                7. В случае отзыва мною согласия на обработку персональных данных Оператор вправе
                продолжить обработку персональных данных без моего согласия при наличии оснований,
                предусмотренных Федеральным законом №152-ФЗ «О персональных данных» от 27.07.2006
                г.
              </p>
              <template #footer>
                <Button label="Ок" severity="success" @click="displayConsent = false" autofocus />
              </template>
            </Dialog>



            <Dialog
              v-model:visible="displaySentOrder" modal :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
            >
              <p name="create-order" class="m-0 create-order-p">
                Ваш заказ создан! Мы свяжемся с вами в ближайшее время!
              </p>
              <template #footer>
                <Button label="Ок" severity="success" @click="okOrder" autofocus />
              </template>
            </Dialog>

            </div>
          </div>
          <div class="flex pt-4 justify-between">
            <Button
              label="Назад"
              severity="secondary"
              icon="pi pi-arrow-left"
              @click="prevCallback"
            />
            <Button
              label="Подтвердить"
              :disabled="!canOrder"
              @click="submitOrder"
            />
          </div>
        </template>
      </StepperPanel>
    </Stepper>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, unref } from "vue";
import { createOrder } from "@/service/ProductService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Stepper from "primevue/stepper";
import StepperPanel from "primevue/stepperpanel";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Checkbox from 'primevue/checkbox';
import Feedback from "@/components/Feedback.vue";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";


// const CreateDialog = defineComponent(Dialog);
// localStorage.clear("cart");
const cart = ref(JSON.parse(localStorage.getItem("cart")));
// console.log("cart:  ", cart.value);

const store = useStore();
const router = useRouter();
// const productId = ref(route.params.id);
// const visible = ref(false);
const delivery = ref(true);
const products = ref([]);
const selectedProduct = ref(null);
// const filteredPrices = ref([]);
const cartData = ref([]);
let newCart = [];
const totalParameters = ref(0);

const totalSum = computed(() => {
  let summa = 0;
  cart.value.forEach((selectedProduct) => {
    summa += selectedProduct.parameters.reduce(
      (sum, param) => sum + (param.quantity || 0) * param.price,
      0
    );
  });
  return summa;
});
const changeCart = async () => {
  const newCart = [];
  cart.value.forEach((selectedProduct) => {
    // console.log('selectedProduct...: ', selectedProduct);
    if (selectedProduct.parameters.length > 0) {
      const filteredParameters = selectedProduct.parameters
        .filter(
          (param) =>
            typeof param.quantity !== "undefined" &&
            param.quantity !== null &&
            param.quantity !== 0
        )
        .map((param) => ({
          value: param.value,
          price: param.price,
          quantity: param.quantity,
        }));

      if (filteredParameters.length > 0) {
        const cartData = {
          productId: selectedProduct.productId,
          price_in: selectedProduct.price_in,
          title: selectedProduct.title,
          parameters: filteredParameters,
        };

        // console.log("cartData: ", cartData);
        newCart.push(cartData);
      }
    }
  });

  // console.log("newCart: ", newCart);

  localStorage.clear("cart");
  localStorage.setItem("cart", JSON.stringify(newCart));

  const cartTest = JSON.parse(localStorage.getItem("cart"));

  if (cartTest) {
    let totalParameters = 0;
    cartTest.forEach((product) => {
      if (product.parameters) {
        totalParameters += product.parameters.length;
      }
    });
    // console.log("totalParameters: ", totalParameters);
    store.commit("updateTotalParameters", totalParameters);
  }

  // console.log("cartTest: ", cartTest);
};



const order = ref({
      name: "",
      phone: "",
      email: "",
      comment: "",
      delivery: "",
    });

let newOrder = []

const consent = ref(true);
const displayConsent = ref(false);
const displaySentOrder = ref(false)

const canOrder = computed(() => {
  return (
    order.value.name.trim() !== "" &&
    (order.value.phone.trim() !== "" || order.value.email.trim() !== "") &&
    consent.value
  );
});

function showConsentDialog() {
  displayConsent.value = true;
}

function okOrder() {
  displaySentOrder.value = false;
  router.push('/');
}

const submitOrder = async () => {
      if (!canOrder.value) return;

      try {
        const unrefOrder = JSON.stringify(unref(order))
        newOrder.push(JSON.parse(unrefOrder));
        const prodOrder = JSON.parse(localStorage.getItem("cart"));
        newOrder.push(prodOrder);
        // console.log('newOrder: ', newOrder);
        await createOrder(newOrder);
        displaySentOrder.value = true;



        localStorage.clear("cart");
        let totalParameters = 0;
        store.commit("updateTotalParameters", totalParameters);

      } catch (error) {
        console.error("Error in submitOrder", error);
      };
      order.value = {
        name: "",
        phone: "",
        email: "",
        comment: "",
        delivery: "",
      };
    }

</script>

<style>

.p-inputtext {
  max-width: 370px;
  margin-left: 0;
  width: 100%;
}

.table-inp {
  font-size: 12px;
}

.responsive-input-number,
.p-inputnumber,
.p-inputnumber-input {
  max-width: 200px;
  min-width: 40px;
}

.total-sum {
  margin: 2% 0;
}

.buy {
  margin-left: 2%;
}

.table {
  display: flex;
  margin: 2% 0;
  justify-content: center;
}

.mobile-view {
  display: none;
}


@media (max-width: 768px) {
  .p-stepper-separator {
      display: none;
  }

  .p-stepper-nav {
      min-width: 370px;
      overflow-x: hidden;
  }

  .responsive-input-number,
  .p-inputnumber,
  .p-inputnumber-input {
    font-size: 11px;
  }

  .table-inp {
    width: 60px;
    font-size: 10px;
  }

  .data-table {
    font-size: 11px;
  }

  .table {
    margin: 0 1rem;
  }
}

</style>

