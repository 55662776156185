<template>
  <div class="map-container">
    <div class="map-caption">
      <h3>КАК НАС НАЙТИ</h3>

      <a href="tel:+77057555277">
        <span class="text"><b>Тел: &nbsp&nbsp</b> +7 705 755 5277</span>
      </a>
      <a href="mailto:ms7555277@yandex.kz">
        <span class="text"><b>Почта: &nbsp&nbsp</b> ms7555277@yandex.kz</span>
      </a>
      <a
        href="https://wa.me/+77057555277?text=Здравствуйте,%20интересует%20продукция%20продукция%20стальной%20марки."
      >
        <span class="text"
          ><b>WhatsApp: &nbsp&nbsp</b> https://wa.me/+77057555277</span
        > </a
      ><br />
      <span class="text">Адрес офиса: &nbsp&nbsp Алма-Ата, Тимирязева 42 корпус 22</span>
      <span class="text">Адрес производства: &nbsp&nbsp Алма-Ата, Казыбаева 286</span>
    </div>
    <div id="map-block">
      <div class="z-0" id="map"></div>
    </div>
    <!-- <div id="map"></div> -->
  </div>

  <h3 style="margin: 3% 8%">Товарищество с ограниченной ответственностью «Стальная марка - Алматы»</h3>
  <div>
  <div v-for="item in items" :key="item.id" class="inf-card">
    <h3>{{ item.title }}</h3>
    <p class="text-justify" v-for="(content, index) in item.content" :key="index">
          {{ content }}
        </p>
  </div>

  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Map from "@/components/Map";
import Card from "primevue/card";

export default {
  components: {
    Map,
    Card,
  },
  setup() {
    const items = ref([]);

    onMounted(async () => {
      try {
        const response = await fetch("/contacts.json");
        if (response.ok) {
          items.value = await response.json();
          // console.log(items);
        } else {
          console.error("Ошибка HTTP:", response.status);
        }
      } catch (error) {
        console.error("Ошибка:", error);
      }
    });
    // console.log(items)
    return { items };
  },
  data() {
    return {
      coordinates: [43.310934, 76.916719],
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const { ymaps } = window;

      ymaps.ready(() => {
        const map = new ymaps.Map("map", {
          center: this.coordinates,
          zoom: 15,
        });

        const placemark = new ymaps.Placemark(
          this.coordinates,
          {},
          {
            preset: "islands#icon",
            iconColor: "#0095b6",
          }
        );

        map.geoObjects.add(placemark);
      });
    },
  },
};
</script>

<style>
/* #map-wrap {
  width: 90%;
  height: 90%;
} */

.inf-card {
  background-color: #333544;
  margin: 3% 8%;
  padding: 3%;
  border-radius: 6px;
  align: left;
}


#map-block {
  width: 84%;
  height: 300px;
  margin: 2% 8%;
  /* margin-top: 0; */
  justify-content: center;
}

/* #map-settings {
  height: 300px;
} */

#map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 2500;
}

.text {
  display: flex;
  text-align: left;
  /* margin-bottom: 15px; */
  margin: 1% 8%;
  /* color: white; */
  /* text-decoration: none; */
}

.map-caption a {
  color: white;
  text-decoration: none;
  /* color: rgb(163, 183, 255); */
}

.map-caption a:hover {
  color: rgb(163, 183, 255);
}
</style>
